.signin-info-bottom-link {
  text-align: end !important;
  color: white !important;
  font-size: 18px !important;
}

.pull-right {
  float: right;
}
.custom-form{
  .input-group{
    p{
      color: #CFCFD0;
      margin-left: 14px;
      font-weight: 400;
    }
  }
}

.signuppage-info-bottom-link {
  color: white !important;
  font-size: 18px !important;
}
.signinpt {
  padding-top: 3.5rem !important;
}

.min-width-45 {
  min-width: 45% !important;
}

@media screen and (min-width: 180px) and (max-width: 961px) {
  .signuppage-info-bottom-link {
    width: 100%;
    text-align: center;
  }
  .signin-info-bottom-link {
    text-align: center !important;
  }
  .width-100 {
    width: 100% !important;
  }

}

.login-container{
  width: 480px;
  .login-box{
    h2{
      color: white;
      padding-top: 35px;
      padding-left: 35px;
      font-size: 28px;
    }
  }
  .input-group-prepend{
    position: absolute;
    top: 61%;
    right: 18px;
  }
  .logo-link{
    text-decoration: none;
  }
  .signin-logo {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 0px 0px 6px;
    justify-content: center;
    position: relative;
    margin-bottom: 17px;
    text-decoration: none;
    img {
      height: 59px;
      margin-right: 5px;
    }
    span{
      color: white;
      font-size: 15px;
      font-weight: 400;
  }
    .app-name {
      font-size: 25px;
      font-weight: 700;
      color: white;
      text-decoration: none;
      width: 100%;
      display: flex;
    }
  }
  .field-error{
    background-color: #26171E;
      padding: 8px;
      font-size: 13px;
      width: 100%;
      display: flex;
      border-radius: 50px;
      color: #FF6C6C;
      align-items: center;
      column-gap: 5px;
      margin-top: 7px;
  }
  .forget-password{
    margin-top: 10px;
    a{
      color: #8067FF;
      font-weight: 500;
      font-size: 15px;
      text-decoration: none;
    }
  }
  .btn-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    width: 100%;
    margin-top: 20px;
    button{
      width: 100%;
      padding: 15px;
    }
    a{
      width: 100%;
      text-decoration: none;
    }
  }
  
}


